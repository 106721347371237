import React from 'react';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="landing-page-container">
      <h1 className="title">BlissFuze</h1>
      <h2 className="subtitle">Your Mental Health Companion</h2>
      <p className="description">
        BlissFuze is an innovative app designed to support your mental well-being. 
        Stay tuned for its launch, coming soon!
      </p>
      <div className="coming-soon">App Coming Soon</div>
    </div>
  );
}

export default LandingPage;
